import { Avatar, Badge, Box, Divider, Flex, Grid, Tabs, Text } from "@mantine/core";
import { IconFileText, IconId, IconMail, IconPhone, IconUser, IconUserCheck } from "@tabler/icons";
import React from "react";
import { useSelector } from "react-redux";
import noimage from "../../assets/no-image.png";
import { IAuth } from "../../interfaces/IAuth";
import { IReference } from "../../models/General";
import { Form } from "../form";
import { DatePickerField } from "../form/date-picker-field";
import { TextField } from "../form/text-field";
import { UserRoleList } from "./role";

export function UserDetail({ action, onCancel }: { action: any; reloadTable?: any; onCancel?: () => void }) {
  const [activeTab, setActiveTab] = React.useState<any>("PROFILE");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { departmentUnits, employeeUnits, userStatus } = useSelector((state: any) => state.general);
  const [data] = React.useState<any>({
    businessId: "",
    familyName: "",
    firstName: "",
    lastName: "",
    registerNo: "",
    departmentUnitId: "",
    employeeUnitId: "",
    phone: "",
    phone2: "",
    email: "",
    email2: "",
    socialFbLink: "",
    roleId: "",
    roleEnableDate: "",
    roleDisableDate: "",
    send: false,
    regUserName: "",
    username: "",
    ...(action && { ...action[1] }),
  });

  const booleanStatus = (val: boolean) => {
    switch (val) {
      case true: {
        return (
          <Badge color="green" size="md" radius="sm" variant="outline">
            Тийм
          </Badge>
        );
      }
      case false: {
        return (
          <Badge color="red" size="md" radius="sm" variant="outline">
            Үгүй
          </Badge>
        );
      }
    }
  };

  return (
    <Grid gutter="md">
      <Grid.Col span={3}>
        <Box
          h="100%"
          sx={(theme) => ({
            border: `1px solid  ${theme.colors.gray[4]}`,
            borderRadius: theme.radius.md,
          })}
          px={20}
          py={16}>
          <Flex align="center" direction="column" gap="md" justify="center">
            <Box sx={{ position: "relative" }}>
              <Avatar alt="user-avatar.alt" radius={100} size={87} src={action[1]?.avatar ? action[1]?.avatar : noimage} />
            </Box>
            <Text fw={600} fz="xl" c="#3e166e">
              {`${action[1]?.lastName} ${action[1]?.firstName}` || "-"}
            </Text>
            <Text fw={700} fz="sm" c="gray">
              {action[1]?.role?.name || "-"}
            </Text>

            <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="Холбоо барих" />
            <Flex direction="column" gap="xs" justify="start" align="start" w="100%">
              <Flex gap="sm">
                <IconMail color="#3e166e" />
                <Text fw={500} c="gray">
                  {action[1]?.email || "-"}
                </Text>
              </Flex>
              <Flex gap="sm" justify="start" align="start" w="100%">
                <IconPhone color="#3e166e" />
                <Text fw={500} c="gray">
                  {action[1]?.phone || "-"}
                </Text>
              </Flex>
              <Flex gap="sm" justify="start" align="start" w="100%">
                <IconUser color="#3e166e" />
                <Text fw={500} c="gray">
                  {action[1]?.departmentUnit || "-"}
                </Text>
              </Flex>
              <Flex gap="sm" justify="start" align="start" w="100%">
                <IconId color="#3e166e" />
                <Text fw={500} c="gray">
                  {action[1]?.employeeUnit || "-"}
                </Text>
              </Flex>
            </Flex>
            <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="Статус төлөв" />
            <Flex direction="column" gap="xs" justify="start" align="start" w="100%">
              <Text fw={500} c="gray">
                Хэрэглэгчийн статус:
              </Text>
              <Badge
                size="md"
                radius="sm"
                variant="outline"
                style={{
                  border: `${(userStatus || []).find((c: IReference) => c?.code === action[1]?.userStatus)?.color} 1px solid`,
                  color: `${(userStatus || []).find((c: IReference) => c?.code === action[1]?.userStatus)?.color}`,
                }}>
                {(userStatus || []).find((c: IReference) => c?.code === action[1]?.userStatus)?.name || "-"}
              </Badge>
              <Text fw={500} c="gray">
                И-мэйл баталгаажилт:
              </Text>
              {booleanStatus(action[1]?.isEmailVerified)}
              <Text fw={500} c="gray">
                Утас баталгаажилт:
              </Text>
              {booleanStatus(action[1]?.isPhoneVerified)}
              {/* <Text fw={500} c="gray">
                ДАН систем баталгаажилт:
              </Text>
              {booleanStatus(action[1]?.isDanVerified)} */}
            </Flex>
          </Flex>
        </Box>
      </Grid.Col>
      <Grid.Col span={9}>
        <Tabs variant="default" value={activeTab} onTabChange={(value: string) => setActiveTab(value)} h="100%">
          <Tabs.List>
            <Tabs.Tab value="PROFILE" icon={<IconFileText color={activeTab === "PROFILE" ? "#3e166e" : "#44566C"} />}>
              <Text size="sm" weight={500} c="#44566C">
                Үндсэн мэдээлэл
              </Text>
            </Tabs.Tab>
            {(user?.permissions || []).filter((c) => c.module === "USER" && c.code === "USER_MANAGEMENT" && c.isView).length > 0 && (
              <Tabs.Tab value="EXP_HISTORY" icon={<IconUserCheck color={activeTab === "EXP_HISTORY" ? "#3e166e" : "#44566C"} />}>
                <Text size="sm" weight={500} c="#44566C">
                  Эрхийн түүх
                </Text>
              </Tabs.Tab>
            )}
            {/* <Tabs.Tab value="SYSTEM_HISTORY" icon={<IconHistory color={activeTab === "SYSTEM_HISTORY" ? "#3e166e" : "#44566C"} />} disabled>
              <Text size="sm" weight={500} c="#44566C">
                Системийн түүх
              </Text>
            </Tabs.Tab> */}
          </Tabs.List>
          <Tabs.Panel value="PROFILE">
            <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ҮНДСЭН МЭДЭЭЛЭЛ" />
            <Form initialValues={data} validationSchema={[]} onSubmit={() => ""}>
              {({ values }) => {
                return (
                  <Box
                    h="100%"
                    sx={(theme) => ({
                      border: `1px solid  ${theme.colors.gray[4]}`,
                      borderRadius: theme.radius.md,
                    })}
                    px={20}
                    py={16}>
                    <Grid>
                      <Grid.Col span={6}>
                        <TextField disabled name="username" placeholder="Хэрэглэгчийн нэр" label="Хэрэглэгчийн нэр" />
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <DatePickerField required={false} disabled name="createdAt" placeholder="Бүртгэсэн огноо" label="Бүртгэсэн огноо" />
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <TextField disabled name="registerNo" placeholder="Ажилтны регистр №" label="Ажилтны регистр №" />
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <TextField disabled name="familyName" placeholder="Ажилтны ургийн овог" label="Ажилтны ургийн овог" />
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <TextField disabled name="lastName" placeholder="Ажилтны эцэг эхийн нэр" label="Ажилтны эцэг эхийн нэр" />
                      </Grid.Col>

                      <Grid.Col span={6}>
                        <TextField disabled name="firstName" placeholder="Ажилтны өөрийн нэр" label="Ажилтны өөрийн нэр" />
                      </Grid.Col>

                      <Grid.Col span={6}>
                        <TextField disabled name="departmentUnit" placeholder="Харъяалах нэгжийн нэр" label="Харъяалах нэгжийн нэр" />
                      </Grid.Col>

                      <Grid.Col span={6}>
                        <TextField disabled name="employeeUnit" placeholder="Албан тушаалын нэр" label="Албан тушаалын нэр" />
                      </Grid.Col>
                      {/* <Grid.Col span={6}>
                        <SelectField
                          options={(departmentUnits || []).map((c: IReference) => ({ label: c?.name, value: c?.id }))}
                          disabled
                          name="departmentUnitId"
                          placeholder="Харъяалах нэгжийн нэр"
                          label="Харъяалах нэгжийн нэр"
                        />
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <SelectField
                          options={(employeeUnits || [])
                            .filter((c: IReference) => c?.departmentUnitId === values?.departmentUnitId)
                            ?.map((c: IReference) => ({ label: c?.name, value: c?.id }))}
                          disabled
                          name="employeeUnitId"
                          placeholder="Албан тушаалын нэр"
                          label="Албан тушаалын нэр"
                        />
                      </Grid.Col> */}
                      <Grid.Col span={12}>
                        <Grid.Col span={12}>
                          <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ХОЛБОО БАРИХ" />
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <TextField disabled name="phone" placeholder="Утасны дугаар №1" label="Утасны дугаар №1" />
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <TextField disabled name="phone2" placeholder="Утасны дугаар №2" label="Утасны дугаар №2" />
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <TextField disabled name="email" placeholder="И-мэйл хаяг №1" label="И-мэйл хаяг №1" />
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <TextField disabled name="email2" placeholder="И-мэйл хаяг №2" label="И-мэйл хаяг №2" />
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <TextField disabled name="socialFbLink" placeholder="Facebook хаяг" label="Facebook хаяг" />
                      </Grid.Col>
                      {/* <Grid.Col span={12}>
                        <Divider />
                      </Grid.Col> */}
                      {/* <Grid.Col span={12}>
                        <Flex direction="row" justify="flex-end">
                          <Button variant="outline" key={2}>
                            Төлөв өөрчлөх
                          </Button>
                          <Flex gap="md">
                            <Button variant="outline" type="submit" key={3}>
                              Засах
                            </Button>
                          </Flex>
                        </Flex>
                      </Grid.Col> */}
                    </Grid>
                  </Box>
                );
              }}
            </Form>
          </Tabs.Panel>
          <Tabs.Panel value="SYSTEM_HISTORY">
            <></>
          </Tabs.Panel>

          {(user?.permissions || []).filter((c) => c.module === "USER" && c.code === "USER_MANAGEMENT" && c.isView).length > 0 && (
            <Tabs.Panel value="EXP_HISTORY">
              <UserRoleList data={action} />
            </Tabs.Panel>
          )}
        </Tabs>
      </Grid.Col>
    </Grid>
  );
}
