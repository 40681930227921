import React, { forwardRef } from "react";
import * as yup from "yup";
import { Form } from "../form";
import useSwr from "swr";
import { Alert, Avatar, Badge, Box, Button, Divider, Flex, Grid, Input, Modal, Paper, Text } from "@mantine/core";
import { IGeneral } from "../../interfaces/IGeneral";
import { useSelector } from "react-redux";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { ProductsApi, SectorApi } from "../../apis";
import { useNavigate, useParams } from "react-router";
import { IAuth } from "../../interfaces/IAuth";
import { dateSecFormat } from "../../utils/date";
import { IAccount, IBusinessSectors, IReference } from "../../models/General";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { MultiSelectField } from "../form/multi-select-field";
import { SelectItemField } from "../form/select-item-field";
import { IconAlertCircle, IconPhoto } from "@tabler/icons";
import { NumberCurrencyField } from "../form/number-currency-field";
import { BuyerLedProgramAnchorForm } from "./anchor-form";
import { Sector } from "../sector";
import { SubSector } from "../sub-sector";
import { SelectField } from "../form/select-field";

const schema = yup.object({
  name: yup.string().required("Заавал бөглөнө!").max(255, "Хэт урт байна!").nullable(),
  resolutionNo: yup.string().max(45, "Хэтэрхий урт байна.").required("Заавал бөглөнө!").nullable(),
  disburseAccId: yup.string().required("Заавал бөглөнө!").nullable(),
  feeAccId: yup.string().required("Заавал бөглөнө!").nullable(),
  repayAccId: yup.string().required("Заавал бөглөнө!").nullable(),
  taskSchedules: yup.array(),
  rechargeable: yup.string().when("businessId", (businessId, field) => (businessId ? yup.string().required("Заавал бөглөнө!").nullable() : field)),
  programSector1Ids: yup.array().min(1, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
  programSector2Ids: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required("Заавал бөглөнө!").nullable(),
      }),
    )
    .min(1, "Заавал бөглөнө!")
    .required("Заавал бөглөнө!")
    .nullable(),
  programLimit: yup
    .string()
    .when("businessId", (businessId, field) =>
      businessId
        ? yup
            .number()
            .max(9999999999999, "13-с бага оронтой дүн оруулна уу!")
            .min(0, "0-с их дүн оруулна уу!")
            .typeError("Зөвхөн тоо оруулна уу!")
            .required("Заавал бөглөнө!")
            .nullable()
        : field,
    ),
});

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  value: any;
  item: any;
}

export type IBuyerProgramFormData = {
  type: string | undefined;
  productType: string | undefined;
  productId: string | undefined;
  name: string | undefined;
  resolutionNo: string | undefined;
  fundType: string | undefined;
  description: string | undefined;
  programLimit: string | undefined;
  disburseAccId: string | undefined;
  feeAccId: string | undefined;
  repayAccId: string | undefined;
  businessId: string | null;
  rechargeable: string | null;
  programSector1Ids: [];
  programSector2Ids: [];
  programSector3Ids: [];
  operation: string;
};

export function BuyerLedMainForm({
  action,
  setType,
  onSubmit: _onSubmit,
}: {
  action: any;
  setType: any;
  onSubmit: (values: IBuyerProgramFormData, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => void;
}) {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const [anchorAdd, setAnchorAdd] = React.useState<any[]>([]);
  const { productTypes, programRegistrationStatus, programStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const [initialData] = React.useState<IBuyerProgramFormData>({
    type: "",
    productType: "",
    productId: "",
    name: "",
    resolutionNo: "",
    fundType: "",
    description: "",
    programLimit: "",
    disburseAccId: "",
    feeAccId: "",
    repayAccId: "",
    businessId: "",
    rechargeable: "TRUE",
    programSector1Ids: [],
    programSector2Ids: [],
    programSector3Ids: [],
    operation: "INACTIVE",
    ...(action && action[0] === "update"
      ? {
          ...action[1],
          programLimit: parseInt(action && action[1]?.programLimit),
          businessId: action && action[1]?.anchor?.businessId,
          rechargeable: action && action[1]?.rechargeable ? "TRUE" : "FALSE",
          programSector1Ids:
            (action && (action[1]?.programSectors || []).filter((c: any) => c.level === 1)?.map((item: any) => item?.economicalSectorId)) || [],
          programSector2Ids:
            (action[1] &&
              (action[1]?.programSectors || [])
                .filter((c: any) => c.level === 2)
                ?.map((item: any) => {
                  return {
                    id: item.economicalSectorId,
                  };
                })) ||
            [],
          operation: action && action[1]?.programStatus,
          programSector3Ids:
            (action[1] &&
              (action[1]?.programSectors || [])
                .filter((c: any) => c.level === 3)
                ?.map((item: any) => {
                  return {
                    id: item.economicalSectorId,
                  };
                })) ||
            [],
        }
      : {}),
  });

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ image, label, value, item, ...others }: ItemProps, ref) => (
    <Flex align={"center"} gap={8} ref={ref} {...others}>
      <Avatar size={32} src={item?.icon || "#"}>
        <IconPhoto />
      </Avatar>
      <div>
        <Text size="sm">{label}</Text>
        <Text size="xs" opacity={0.65}>
          {item?.bankName}
        </Text>
      </div>
    </Flex>
  ));

  const { data: productData } = useSwr(`/buyer-led-program/product/list/${params.code}/BUYER_LED`, async () => {
    if ((user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isCreate).length > 0) {
      try {
        let res = await ProductsApi.select({ query: params.code, type: "BUYER_LED" });
        return res;
      } catch (error) {
        Message.error((error as HttpHandler)?.message!);
      }
    }
    return [];
  });

  const onSubmit = (values: IBuyerProgramFormData) => {
    _onSubmit(values, setLoading);
  };

  const { data: mainCategoryData } = useSwr(`/program/reference/economical_sector/select/main-form/buyer_led`, async () => {
    if ((user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_REFERENCE").length > 0) {
      try {
        let res = await SectorApi.select({ parentId: "", level: "1", query: "", limit: "" });
        return res;
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
      }
    }
    return [];
  });

  const { data: accountDisbursement } = useSwr(
    `/api/program/account_select/BUYER_LED/DISBURSEMENT/${action && action[0] === "update" && action[1]?.disburseAcc?.id}`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isCreate)) {
        try {
          let res = await ProductsApi.account({
            type: "DISBURSEMENT",
            accountId: action && action[0] === "update" ? action[1]?.disburseAcc?.id : "",
          });

          return res;
        } catch (err) {
          Message.error((err as HttpHandler)?.message!);
        }
      }
      return [];
    },
  );

  const { data: accountFee } = useSwr(`/api/program/account_select/BUYER_LED/FEE/${action && action[0] === "update" && action[1]?.feeAcc?.id}`, async () => {
    if ((user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isCreate)) {
      try {
        let res = await ProductsApi.account({
          type: "FEE",
          accountId: action && action[0] === "update" ? action[1]?.feeAcc?.id : "",
        });

        return res;
      } catch (err) {
        Message.error((err as HttpHandler)?.message!);
      }
    }
    return [];
  });

  const { data: accountRepayment } = useSwr(
    `/api/program/account_select/BUYER_LED/REPAYMENT/${action && action[0] === "update" && action[1]?.repayAcc?.id}`,
    async () => {
      if ((user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isCreate)) {
        try {
          let res = await ProductsApi.account({
            type: "REPAYMENT",
            accountId: action && action[0] === "update" ? action[1]?.repayAcc?.id : "",
          });

          return res;
        } catch (err) {
          Message.error((err as HttpHandler)?.message!);
        }
      }
      return [];
    },
  );

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={initialData}>
      {({ values, setFieldValue }) => {
        return (
          <>
            <Grid gutter={24} mt="lg">
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Хөтөлбөрийн код:">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    {values.refCode || "Системээс үүсгэнэ"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Төрөл:">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    {"Buyer-LED"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Санхүүжилтийн бүтээгдэхүүн:">
                  <Flex align="center" gap={8}>
                    {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isCreate).length > 0 ? (
                      <Text fw={500} color="indigo" fz={"sm"}>
                        {productData && productData[0]?.name}, #{productData && productData[0]?.refCode}
                      </Text>
                    ) : (
                      <Text fw={500} color="indigo" fz={"sm"}>
                        -
                      </Text>
                    )}
                    <Text fz="sm" fw={500} color="dimmed" component="div">
                      ({(productTypes || []).find((item) => item.code === "BUYER_LED")?.name || "-"})
                    </Text>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Бүртгэсэн:">
                  <Text fw={500} color="indigo" fz={"sm"}>
                    <Flex gap={5} wrap="wrap">
                      <span>
                        {action && action[0] !== "update" ? (
                          <>
                            {user?.lastName || "-"} {user?.firstName || "-"}
                          </>
                        ) : (
                          <>
                            {values?.regUser?.lastName || "-"} {values?.regUser?.firstName || "-"}
                          </>
                        )}
                      </span>
                      <Text color="dimmed" component="div">
                        {values?.createdAt ? dateSecFormat(values.createdAt) : dateSecFormat(new Date())}
                      </Text>
                    </Flex>
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Бүртгэлийн статус:">
                  <Box style={{ alignItems: "center" }}>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      color={
                        (programRegistrationStatus || []).find((item: IReference) =>
                          item.code === action && action[0] === "update" ? values.registrationStatus : "DRAFT",
                        )?.color
                      }>
                      {
                        (programRegistrationStatus || []).find((item: IReference) =>
                          item.code === action && action[0] === "update" ? values.registrationStatus : "DRAFT",
                        )?.name
                      }
                    </Badge>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <Input.Wrapper label="Хөтөлбөрийн статус:">
                  <Box style={{ alignItems: "center" }}>
                    <Badge
                      size="md"
                      radius="sm"
                      variant="outline"
                      color={(programStatus || []).find((item: IReference) => item.code === values.operation)?.color}>
                      {(programStatus || []).find((item: IReference) => item.code === values.operation)?.name}
                    </Badge>
                  </Box>
                </Input.Wrapper>
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider my={2} />
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <TextField name="name" label="Хөтөлбөрийн нэр:" placeholder="Нэр оруулах" />
              </Grid.Col>
              <Grid.Col md={6} lg={4}>
                <TextField name="resolutionNo" label="Хорооны шийдвэрийн дугаар:" placeholder="Тушаалын дугаарыг оруулна уу" />
              </Grid.Col>
              <Grid.Col md={6} lg={4}></Grid.Col>

              <Grid.Col span={12}>
                <TextareaField name="description" placeholder="Тайлбар оруулах" label="Тайлбар оруулах:" maxLength={1000} withAsterisk={false} />
              </Grid.Col>

              {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_REFERENCE").length > 0 && (
                <Grid.Col span={12}>
                  <MultiSelectField
                    label="Үндсэн ангилал: "
                    name="programSector1Ids"
                    onChange={(item) => {
                      setFieldValue(
                        `programSector2Ids`,
                        item?.map((item: any, index: number) => {
                          return {
                            id: values?.programSector2Ids && values?.programSector2Ids?.length >= 0 ? values?.programSector2Ids[index]?.id || "" : "",
                          };
                        }),
                      );
                    }}
                    options={(mainCategoryData || []).map((item: IBusinessSectors) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    maxSelectedValues={3}
                    placeholder="сонгох"
                  />
                </Grid.Col>
              )}

              {(values?.programSector1Ids || []).map((item: any, index: number) => {
                return (
                  <Grid.Col span={4} key={index}>
                    <Sector sectorId={item} index={index} type="BUYER_LED" setFieldValue={setFieldValue} values={values} />
                  </Grid.Col>
                );
              })}

              {(values?.programSector2Ids || []).map((item: any, index: number) => {
                return (
                  <Grid.Col span={4} key={index}>
                    <SubSector sectorId={item} index={index} type="BUYER_LED-SUB_SECTOR" values={values} setFieldValue={setFieldValue} />
                  </Grid.Col>
                );
              })}

              <Grid.Col span={12}></Grid.Col>
              {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isCreate) && (
                <Grid.Col md={6} lg={4}>
                  <SelectItemField
                    required
                    name="disburseAccId"
                    label="Санхүүжилт шилжүүлэх банк данс (From):"
                    placeholder="Сонголт"
                    options={(accountDisbursement || []).map((item: IAccount) => {
                      return { value: item.id || "", label: `${item.shortName}, ${item.number}` || "", item };
                    })}
                    clearable
                    searchable
                    itemComponent={SelectItem}
                    filter={(value: any, item: any) =>
                      item.label.toLowerCase().includes(value.toLowerCase().trim()) || item.value.toLowerCase().includes(value.toLowerCase().trim())
                    }
                  />
                </Grid.Col>
              )}

              {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isCreate) && (
                <Grid.Col md={6} lg={4}>
                  <SelectItemField
                    required
                    name="feeAccId"
                    label="Шимтгэлийн орлого төвлөрүүлэх данс (To): "
                    placeholder="Сонголт"
                    options={(accountFee || []).map((item: IAccount) => {
                      return { value: item.id || "", label: `${item.shortName}, ${item.number}` || "", item };
                    })}
                    clearable
                    searchable
                    itemComponent={SelectItem}
                    filter={(value: any, item: any) =>
                      item.label.toLowerCase().includes(value.toLowerCase().trim()) || item.value.toLowerCase().includes(value.toLowerCase().trim())
                    }
                  />
                </Grid.Col>
              )}

              {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isCreate) && (
                <Grid.Col md={6} lg={4}>
                  <SelectItemField
                    required
                    name="repayAccId"
                    label="Эргэн төлөлтийг авах банк данс (To): "
                    placeholder="Сонголт"
                    options={(accountRepayment || []).map((item: IAccount) => {
                      return { value: item.id || "", label: `${item.shortName}, ${item.number}` || "", item };
                    })}
                    clearable
                    searchable
                    itemComponent={SelectItem}
                    filter={(value: any, item: any) =>
                      item.label.toLowerCase().includes(value.toLowerCase().trim()) || item.value.toLowerCase().includes(value.toLowerCase().trim())
                    }
                  />
                </Grid.Col>
              )}

              {(user?.permissions || []).filter((c) => c.module === "PROGRAM" && c.code === "SCF_PROGRAM_MANAGEMENT_BL" && c.isCreate).length > 0 && (
                <Grid.Col span={12}>
                  <Divider h={"lg"} />
                  <Flex align="center" justify="space-between" pb={12}>
                    <Text color="dimmed" tt="uppercase" fw={500}>
                      АНКОРЫН МЭДЭЭЛЭЛ
                    </Text>
                    {values.hasAnchor ? (
                      <Button
                        onClick={() => {
                          setFieldValue("businessId", undefined);
                          setFieldValue("anchor", undefined);
                          setFieldValue("bankAccounts", undefined);
                          setFieldValue("checkedAnchor", undefined);
                          setFieldValue("hasAnchor", false);
                        }}
                        variant="outline">
                        анкор хасах
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          setAnchorAdd(["anchor_add", values]);
                        }}
                        variant="outline">
                        Анкор нэмэх
                      </Button>
                    )}
                  </Flex>
                  {!values.hasAnchor && (
                    <Paper>
                      <Alert
                        hidden={showAlert}
                        withCloseButton={true}
                        onClose={() => {
                          setShowAlert(true);
                        }}
                        styles={{ message: { color: "#44566c", fontWeight: 500 } }}
                        icon={<IconAlertCircle size={16} />}
                        title="Таны хянах мэдээлэл!"
                        color="indigo">
                        Та эхлээд хөтөлбөрийн Анкор болох бизнесийг сонгоно уу. Ингэснээр тухайн Анкортой хамтран ажилладаг харилцагчдыг хөтөлбөрт хамруулах боломжтой болно.
                      </Alert>
                    </Paper>
                  )}
                </Grid.Col>
              )}
              {values.hasAnchor && (
                <>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Партнерийн нэр">
                      <Text color="indigo">
                        {values?.anchor?.partner?.businessName || "-"}, #{values?.anchor?.partner?.refCode || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Хөтөлбөрт хамрагдах бизнесийн нэр">
                      <Text color="indigo">
                        {values?.anchor?.profileName || "-"}, #{values?.anchor?.refCode || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Хөтөлбөр дэх анкорын роль">
                      <Text color="indigo">{values?.anchor?.refCode?.match("BU") ? "Худалдан авагч" : "Нийлүүлэгч"}</Text>
                    </Input.Wrapper>
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <NumberCurrencyField precision={2} placeholder="0.00" min={0} max={999999999999} name="programLimit" label="Хөтөлбөрийн лимит:" required />
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <NumberCurrencyField
                      precision={2}
                      placeholder="0.00"
                      min={0}
                      max={999999999999}
                      name="programLimit"
                      label="Худалдан авагчийн лимит:"
                      required
                    />
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <SelectField
                      name="rechargeable"
                      label="Санхүүжилтийн онцлог:"
                      placeholder="Сонгох"
                      options={(
                        [
                          {
                            code: "FALSE",
                            name: "Хөтөлбөрийн лимит 0 болоход зогсоно",
                          },
                          {
                            code: "TRUE",
                            name: "Эргэн төлөлтөөс боломжит лимит өснө.",
                          },
                        ] || []
                      ).map((c) => ({
                        label: c.name,
                        value: c.code,
                      }))}
                    />
                  </Grid.Col>
                  <Grid.Col md={6} lg={4}>
                    <Input.Wrapper label="Нэхэмжлэл батлах санхүү">
                      <Text color="indigo">
                        {values?.anchor?.finUser?.lastName || "-"}, {values?.anchor?.finUser?.firstName || "-"}
                      </Text>
                    </Input.Wrapper>
                  </Grid.Col>
                </>
              )}
            </Grid>
            <Divider h="lg" mt="lg" />
            <Flex direction="row" justify="space-between">
              <Button onClick={() => navigate("/scf-dcf/dcf-program")} variant="default">
                Болих
              </Button>
              <Flex gap="md">
                <Button
                  loading={loading}
                  type="submit"
                  variant="outline"
                  disabled={values?.registrationStatus === "REGISTERED" || values?.registrationStatus === "REVIEWED"}
                  onClick={() => setType("SAVE")}>
                  Хадгалах
                </Button>
                <Button
                  loading={loading}
                  type="submit"
                  disabled={values?.registrationStatus === "REGISTERED" || values?.registrationStatus === "REVIEWED"}
                  onClick={() => setType("NEXT")}>
                  Бүртгэсэн
                </Button>
              </Flex>
            </Flex>
            <Modal
              opened={anchorAdd[0] === "anchor_add"}
              onClose={() => {
                setAnchorAdd([]);
                setFieldValue("businessId", undefined);
              }}
              withCloseButton={false}
              size="xxl"
              centered>
              <BuyerLedProgramAnchorForm setFieldValue={setFieldValue} action={anchorAdd} setAction={setAnchorAdd} values={values} />
            </Modal>
          </>
        );
      }}
    </Form>
  );
}
